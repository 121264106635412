import Component from '~/modules/Component'
import fetcher from '~/helpers/fetcher'
import { trigger } from '~/helpers/event'
import { config, util } from '~/core'

const NAME = 'blog'

export default class Blog extends Component {
  constructor (element) {
    super(element, { name: NAME })

    if (!this.element) {
      return
    }

    if (this.dom.has('url')) {
      this.load()
    }
  }

  async load () {
    const { protocol, host } = location
    const href = this.dom.get('url')[0].getAttribute('href')
    const json = await fetcher('json').get(
      `${protocol}//${host}${config.rss}?url=${href}`
    )

    if (json) {
      util.renderDots(json, this.dom.get('temp')[0], this.dom.get('items')[0])
      setTimeout(() => {
        trigger(window, 'resize')
      }, 200)
    }
  }
}
