import getPrefix from '~/helpers/getPrefix'
import getScrollBarWidth from '~/helpers/getScrollBarWidth'
import doT from 'dot'

const barSize = getScrollBarWidth()
const transform = getPrefix(['transform', 'msTransform'])

const util = {
  scrollBarSize: barSize,
  transformStr: transform,
  renderDots: (json, template, content, add = false) => {
    const tmpl = doT.template(template.innerHTML)
    const compiled = tmpl(json).replace(/\r?\n/g, '<br>')
    if (add) {
      content.innerHTML += compiled
    } else {
      content.innerHTML = compiled
    }
  }
}

export default util
