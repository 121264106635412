import { setStyle } from './style'
import { createElement, remove } from './dom'

const outer = createElement()
const inner = createElement()

/**
 * @return {number}
 */
const getScrollBarWidth = () => {
  setStyle(outer, {
    visibility: 'hidden',
    width: '100px'
  })
  document.body.appendChild(outer)

  const outerWidth = outer.offsetWidth
  setStyle(outer, {
    overflow: 'scroll'
  })
  setStyle(inner, {
    width: '100%'
  })
  outer.appendChild(inner)

  const innerWidth = inner.offsetWidth
  remove(outer)

  return outerWidth - innerWidth
}

export default getScrollBarWidth
