const on = (element, eventType, fn, options = false, target = null) => {
  const delegatorFn = (e) => {
    let el = e.target
    while (el && el !== element) {
      if (el.matches && el.matches(target, element)) {
        e.delegateTarget = el
        fn.call(el, e)
        break
      }
      el = el.parentNode
    }
  }

  element.addEventListener(eventType, target ? delegatorFn : fn, options)

  if (target) return delegatorFn
}

const off = (element, eventType, fn, options = false) => {
  element.removeEventListener(eventType, fn, options)
}

const trigger = (element, eventType) => {
  const e = document.createEvent('HTMLEvents')
  e.initEvent(eventType, false, true)
  element.dispatchEvent(e)
}

export { on, off, trigger }
