import Component from '~/modules/Component'
import Scroller from '~/modules/Scroller'

const SELECTOR = {
  ELEMENT: '[data-animate]'
}
const CLASSES = {
  ANIMATE: 'is-animate'
}

export default class SequenceScroll extends Component {
  constructor (element, { delay = 150 } = {}) {
    super(element)

    this.items = [...this.element.querySelectorAll(SELECTOR.ELEMENT)]
    this.entries = []
    this.delay = delay
    this.bindAll('onScroll', 'onObserve')

    this.observer = new IntersectionObserver(this.onObserve, { threshold: 0.5 })

    this.items.forEach((item) => {
      const target =
        item.dataset.target && document.querySelector(item.dataset.target)
      this.observer.observe(target || item)
    })

    Scroller.add(this.onScroll)
  }

  sortBy (arry) {
    return arry.sort((a, b) => {
      const aRect = a.entry.boundingClientRect
      const bRect = b.entry.boundingClientRect

      if (aRect.top < bRect.top) {
        return -1
      } else if (aRect.top > bRect.top) {
        return 1
      }

      if (aRect.left < bRect.left) {
        return -1
      } else if (aRect.left > bRect.left) {
        return 1
      }
    })
  }

  render () {
    const copyArr = this.entries
    this.entries = []

    this.sortBy(copyArr).forEach((obj, i) => {
      const { target, entry, observe } = obj
      const delay = target.dataset.delay
        ? parseInt(target.dataset.delay)
        : this.delay * i
      setTimeout(() => {
        target.classList.add(CLASSES.ANIMATE)
        observe.unobserve(entry.target)
      }, delay)
    })
  }

  onScroll () {
    if (this.entries.length > 0) {
      this.render()
    }
  }

  onObserve (entries, observe) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i]
      if (entry.isIntersecting) {
        const target = this.items.filter((item) => {
          if (
            item.dataset.target &&
            document.querySelector(item.dataset.target) === entry.target
          ) {
            return item
          }
          return null
        })
        this.entries.push({
          target: target[0] || entry.target,
          entry,
          observe
        })
      }
    }
  }
}
