import doT from 'dot'
import { dom } from '~/core'
import { trigger } from '~/helpers/event'

const SELECT_CLASS = 'is-selected'

export default class Recruit {
  constructor() {
    if (dom.body.classList.contains('page-recruit-top')) {
      this.listSelector = document.querySelector('.js-news-list')
      this.tempSelector = document.querySelector('.js-news-template')
      this.template = doT.template(this.tempSelector.innerHTML)
      this.loadJson()
    }
  }

  async loadJson() {
    fetch('/cms/include.php')
      .then(response => response.json())
      .then(json => this.firstCreate(json))
  }

  async firstCreate(json) {
    this.json = json
    this.create('all')
  }

  async create(area) {
    const createArray = {
      entries: this.json.filter(item => item.area === area || item.area === 'all' || area === 'all').slice(0, 10)
    }
    this.listSelector.innerHTML = this.template(createArray)
    trigger(window, 'resize')
  }
}
