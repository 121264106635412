import SequenceScroll from '~/ui/SequenceScroll'
import SpyNav from '~/ui/SpyNav'
import sleep from '~/helpers/sleep'
import { dom } from '~/core'
import { trigger } from '~/helpers/event'

export default class Lower {
  constructor () {
    this.sequenceScroll = new SequenceScroll(document.body)
    this.checkHashes()

    if (
      dom.body.classList.contains('page-recruit-top') ||
      dom.body.classList.contains('page-shisetsu-home')
    ) {
      // console.log('spy nav')
      this.spynav = new SpyNav('.js-spy-nav')
    }

    setTimeout(() => {
      trigger(window, 'scroll')
    }, 300)
  }

  async checkHashes () {
    const { hash } = location

    if (hash) {
      let hasHashToSelector = null

      try {
        hasHashToSelector = document.querySelector(hash)
      } catch (e) {
        hasHashToSelector = false
      }

      if (hasHashToSelector) {
        await sleep(200)
        hasHashToSelector.scrollIntoView()
      }
    }
  }
}
