import bindAll from '~/helpers/bindAll'
import { isString } from '~/helpers/type'
import { remove } from '~/helpers/dom'

export default class Component {
  constructor (element, { target = null, name = null } = {}) {
    this.element = isString(element) ? document.querySelector(element) : element

    if (!this.element) {
      return
    }

    if (name) {
      this.dataAttr = `${name}-el`
      this.selector = `[data-${this.dataAttr}]`
      this.dom = this.getElements(target || this.element)
    }
  }

  bindAll (...eventNames) {
    bindAll(this, ...eventNames)
  }

  getElements (target) {
    return this.createElMap(this.selector, this.dataAttr, target)
  }

  createElMap (selector, dataAttr, target = document) {
    return [...target.querySelectorAll(selector)].reduce((acc, el) => {
      const name = el.getAttribute(`data-${dataAttr}`)
      if (name) {
        if (!acc.has(name)) {
          acc.set(name, [el])
        } else {
          acc.get(name).push(el)
        }
      }
      return acc
    }, new Map())
  }

  mount (nodes) {
    this.element.appendChild(nodes)
  }

  dispose () {
    remove(this.element)
  }
}
