import bindAll from '~/helpers/bindAll'
import scrollTo from '~/vendors/animejs/scrollTo'
import getSelectorFromElement from '~/helpers/getSelectorFromElement'
import emitter from '~/modules/EventEmitter'
import { isNull } from '~/helpers/type'
import { on, off } from '~/helpers/event'

const NAME = 'smooth-scroll'
const SELECTOR = {
  ELEMENTS: 'a:not(.no-smooth)[href^="#"]'
}
const EVENTS = {
  CLICK: `${NAME}:click`
}

export default class SmoothScroll {
  constructor (
    selector = SELECTOR.ELEMENTS,
    { duration = 800, easing = 'easeOutQuint' } = {}
  ) {
    this.selector = selector
    this.options = {
      duration,
      easing,
      offset: 0
    }

    bindAll(this, 'onClick')
    this.bindEvents()
  }

  bindEvents () {
    this.delegateAnchor = on(
      document,
      'click',
      this.onClick,
      false,
      this.selector
    )
  }

  unbindEvents () {
    off(document, 'click', this.delegateAnchor)
  }

  onClick (e) {
    const target = e.delegateTarget

    if (target.tagName.toLowerCase() === 'a') {
      e.preventDefault()
    }

    emitter.emit(EVENTS.CLICK)

    const toElement =
      target.getAttribute('href') === '#' ? 0 : getSelectorFromElement(target)

    if (isNull(toElement)) {
      return
    }

    scrollTo(toElement, this.options)
  }

  static get EVENTS () {
    return EVENTS
  }
}
