import env from './env'
import { isFunction } from '~/helpers/type'

const log = (...args) => {
  if (!env.isDev() || !('console' in window)) {
    return
  }

  let method = 'log'

  if (isFunction(args[0])) {
    method = args[0]
    args = args.slice(1, args.length)
  }

  window.console[method](args)
}

export default log
