import Component from '~/modules/Component'
import Scroller from '~/modules/Scroller'
import debounce from '~/helpers/debounce'
import { on, off } from '~/helpers/event'
import { setStyle } from '~/helpers/style'
import { dom } from '~/core'

const NAME = 'wheel'

export default class PreventWheel extends Component {
  constructor(element) {
    super(element, { name: NAME })

    if (!this.element && !('ontouchstart' in dom.html)) {
      return false
    }

    this.isScrolling = false
    this.disenable()
    this.bindAll('onScroll', 'onAfterScroll', 'onMouseMove', 'onMouseLeave')
    this.bindEvents()
    Scroller.add(this.onScroll)
    Scroller.add(debounce(this.onAfterScroll, 150))
  }

  bindEvents() {
    on(this.element, 'mousemove', this.onMouseMove)
    on(this.element, 'mouseleave', this.onMouseLeave)
  }

  unbindEvents() {
    off(this.element, 'mousemove', this.onMouseMove)
    off(this.element, 'mouseleave', this.onMouseLeave)
  }

  onScroll() {
    this.isScrolling = true
  }

  onAfterScroll() {
    this.isScrolling = false
  }

  onMouseMove() {
    this.isScrolling ? this.disenable() : this.enabled()
  }

  onMouseLeave() {
    this.isScrolling = false
    this.disenable()
  }

  enabled() {
    setStyle(this.dom.get('canvas'), { pointerEvents: 'auto' })
  }

  disenable() {
    setStyle(this.dom.get('canvas'), { pointerEvents: 'none' })
  }
}
