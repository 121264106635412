import Component from '~/modules/Component'
import getSelectorFromElement from '~/helpers/getSelectorFromElement'
import { on, off, trigger } from '~/helpers/event'

const NAME = 'tab'
const CLASSES = {
  SELECTED: 'is-selected'
}

export default class Tab extends Component {
  constructor (element, { startIndex = 0 } = {}) {
    super(element, {
      name: NAME
    })

    if (!this.element) {
      return
    }

    this.startIndex = startIndex
    this.bindAll('onClick')
    this.bindEvents()
    this.init()
  }

  init () {
    const buttons = this.dom.get('button')

    ;[...buttons, ...this.dom.get('pane')].forEach((el) => {
      if (el.classList.contains(CLASSES.SELECTED)) {
        el.classList.remove(CLASSES.SELECTED)
      }
    })

    buttons[this.startIndex].click()
  }

  bindEvents () {
    this.delegateFn = on(
      this.element,
      'click',
      this.onClick,
      false,
      `[data-${this.dataAttr}="button"]`
    )
  }

  unbindEvents () {
    off(this.element, 'click', this.delegateFn)
  }

  onClick (e) {
    const target = e.delegateTarget
    const currentPane = getSelectorFromElement(target)
    const buttons = this.dom.get('button')
    const panes = this.dom.get('pane')

    if (target.tagName.toLowerCase() === 'a') {
      e.preventDefault()
    }

    ;[...panes, ...buttons].forEach((el) => {
      if (el === currentPane || el === target) {
        el.classList.add(CLASSES.SELECTED)
      } else if (el.classList.contains(CLASSES.SELECTED)) {
        el.classList.remove(CLASSES.SELECTED)
      }
    })
  }
}
