import { isArray } from './type'

/**
 * 配列のからランダムの要素を返す
 * @param  {Array} arry
 * @return {Array}
 */
const sample = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

/**
 * @param  {Array} arr
 * @return {Array}
 */
const shuffle = (arr) => {
  let m = arr.length
  while (m) {
    const i = Math.floor(Math.random() * m--)
    ;[arr[m], arr[i]] = [arr[i], arr[m]]
  }
  return arr
}

/**
 * 配列へ変換する
 * @param  {Array|string} val
 * @return {Array}
 */
const castArray = (val) => {
  return isArray(val) ? val : [val]
}

/**
 * 配列から重複をなくす
 * @param  {Array} arr
 * @return {Array}
 */
const unique = (arr) => {
  const unique = (val, i, arry) => arry.indexOf(val) === i
  return arr.filter(unique)
}

/**
 * 配列を並列化
 * @param  {Array}  arr
 * @param  {number} depth
 * @return {Array}
 */
const flatten = (arr, depth = 1) => {
  return depth !== 1
    ? arr.reduce((a, v) => a.concat(isArray(v) ? flatten(v, depth - 1) : v), [])
    : arr.reduce((a, v) => a.concat(v), [])
}

/**
 * 配列を並列化
 * @param  {Array} arr
 * @return {Array}
 */
const deepFlatten = (arr) => {
  return [].concat(...arr.map((v) => (isArray(v) ? deepFlatten(v) : v)))
}

const groupBy = (arr, fn) => {
  return arr
    .map(typeof fn === 'function' ? fn : (val) => val[fn])
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i])
      return acc
    }, {})
}

const forEachRight = (arr, callback) => {
  return arr
    .slice(0)
    .reverse()
    .forEach(callback)
}

const range = (start, end, step = 1) =>
  [...Array(Math.max(Math.ceil((end - start) / step), 0)).keys()].map(
    (n) => n * step + start
  )

const average = (...nums) => {
  return nums.reduce((acc, val) => acc + val, 0) / nums.length
}

const arrayTo2d = (arry, num) => {
  let i = 0
  const result = []
  while (i < arry.length) {
    if (i % num === 0) {
      result.push([])
    }
    result[result.length - 1].push(arry[i++])
  }
  return result
}

export {
  sample,
  shuffle,
  castArray,
  unique,
  flatten,
  deepFlatten,
  groupBy,
  forEachRight,
  range,
  average,
  arrayTo2d
}
