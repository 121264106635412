import anime from 'animejs/lib/anime.es.js'
import { dom, detect } from '~/core'
import { AsYouType, isValidNumber } from 'libphonenumber-js'

export default class Validate {
  constructor() {
    this.validate = document.querySelector('.js-validate')
    this.form = this.validate.querySelector('form')
    if (this.form) {
      this.submit = this.form.querySelector('.js-form-submit')
      this.required = Array.from(this.form.querySelectorAll(`[data-validate-elm='required']`))
      this.submit.addEventListener('click', (event) => this.onClickSubmit(event), false)
    }
  }

  onClickSubmit(event) {
    // event.preventDefault()
    const { required } = this
    let reqArray = {}
    let errorArray = []
    Array.from(required, (_elm) => {
      const input = _elm.querySelectorAll('input, select, textarea')
      Array.from(input, (_input) => {
        if (_input.type === 'hidden') {
          return true
        }
        if (!(_input.name in reqArray)) {
          reqArray[_input.name] = {
            elm: _elm,
            value: _input.value,
          }
        }
        if (_input.type === 'email') {
          if (_input.value.match(/.+@.+\..+/)) {
            reqArray[_input.name]['judg'] = true
          } else {
            reqArray[_input.name]['judg'] = false
          }
        } else if (_input.type === 'radio') {
          if (_input.checked) {
            reqArray[_input.name]['judg'] = true
          }
        } else {
          if (_input.value.length) {
            if (_input.name === 'ruby') {
              if (_input.value.match(/^[ぁ-んー　]*$/)) {
                reqArray[_input.name]['judg'] = true
              } else {
                reqArray[_input.name]['judg'] = false
              }
            } else if (_input.name === 'tel') {
              if (this.validateTelNeo(_input.value)) {
                _input.value = this.formatTel(_input.value)
                reqArray[_input.name]['judg'] = true
              } else {
                reqArray[_input.name]['judg'] = false
              }
            } else {
              reqArray[_input.name]['judg'] = true
            }
          } else {
            reqArray[_input.name]['judg'] = false
          }
        }
      })
    })

    if (reqArray.email.value !== reqArray.emailconf.value) {
      reqArray.emailconf.judg = false
    }

    Object.keys(reqArray).forEach((key) => {
      const value = reqArray[key]
      if (value.judg) {
        value.elm.classList.remove('error')
      } else {
        value.elm.classList.add('error')
        errorArray.push(value.elm)
      }
    })

    // console.log(reqArray)
    // console.log(errorArray)

    if (errorArray.length !== 0) {
      this.scrollTo(errorArray[0])
      event.preventDefault()
    }
  }

  validateTelNeo(value) {
    return /^[0０]/.test(value) && isValidNumber(value, 'JP')
  }

  formatTel(value) {
    return new AsYouType('JP').input(value)
  }

  scrollTo(elm) {
    const offset = elm.getBoundingClientRect().top + window.pageYOffset
    const root = detect.isSafari ? dom.body : dom.html
    anime({
      targets: {
        scroll: root.scrollTop,
      },
      scroll: offset - 100,
      duration: 600,
      easing: 'easeInOutQuad',
      update: (current) => {
        root.scrollTop = current.animations[0].currentValue
      },
    }).finished
  }
}
