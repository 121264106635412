/**
 * @param  {*} val
 * @return {boolean}
 */
const isBoolean = (val) => typeof val === 'boolean'

/**
 * @param  {*} val
 * @return {boolean}
 */
const isEmpty = (val) => val == null || !(Object.keys(val) || val).length

/**
 * @param  {*} val
 * @return {boolean}
 */
const isFunction = (val) => typeof val === 'function'

/**
 * @param  {*} val
 * @return {boolean}
 */
const isNull = (val) => val === null

/**
 * @param  {*} val
 * @return {boolean}
 */
const isNumber = (val) => typeof val === 'number'

/**
 * @param  {*} val
 * @return {boolean}
 */
const isArray = (val) => Array.isArray(val)

/**
 * @param  {*} obj
 * @return {boolean}
 */
const isObject = (obj) => obj === Object(obj)

/**
 * @param  {*} val
 * @return {boolean}
 */
const isString = (val) => typeof val === 'string'

/**
 * @param  {*} val
 * @return {boolean}
 */
const isSymbol = (val) => typeof val === 'symbol'

/**
 * @param {*} element
 * @return {boolean}
 */
const isElement = (element) => element instanceof HTMLElement

/**
 * @param  {HTMLElement} element
 * @return {boolean}
 */
const isNodeList = (element) => element instanceof NodeList

/**
 * @param  {HTMLElement} element
 * @return {boolean}
 */
const isHTMLCollection = (element) => element instanceof HTMLCollection

/**
 * @param  {object} obj
 * @return {boolean}
 */
const isValidJSON = (obj) => {
  try {
    JSON.parse(obj)
    return true
  } catch (e) {
    return false
  }
}

export {
  isArray,
  isBoolean,
  isEmpty,
  isElement,
  isFunction,
  isNumber,
  isNull,
  isObject,
  isString,
  isSymbol,
  isNodeList,
  isHTMLCollection,
  isValidJSON
}
