import Component from './Component'
import emitter from './EventEmitter'
import { on, off } from '~/helpers/event'
import { detect } from '~/core'
import { radsToDegrees } from '~/helpers/math'

const NAME = 'swipe'
const EVENTS = {
  SWIPE_START: `${NAME}:start`,
  SWIPE_MOVE: `${NAME}:move`,
  SWIPE_END: `${NAME}:end`
}

export default class Swipe extends Component {
  constructor (element, { dragging = true } = {}) {
    super(element, {
      name: NAME
    })

    this.dragging = dragging
    this.position = { x: 0, y: 0 }
    this.touches = { x: 0, y: 0 }
    this.captureOpts = detect.hasPassive
      ? {
        passive: false
      }
      : false
    this.bindAll('onTouchStart', 'onTouchMove', 'onTouchEnd')
    this.bindEvents()
  }

  setPositions (e) {
    const touches =
      e.type === 'mousemove'
        ? {
          pageX: e.clientX,
          pageY: e.clientY
        }
        : e.changedTouches[0]

    const dx = touches.pageX - this.position.x
    const dy = this.position.y - touches.pageY

    this.touches = { x: dx, y: dy }

    e.direction = this.getDirection(dx, dy)
    e.touches = this.touches

    emitter.emit(EVENTS.SWIPE_MOVE, e)
  }

  getDirection (x, y) {
    const radius = Math.atan2(y, x)
    let angle = Math.round(radsToDegrees(radius))

    if (angle < 0) {
      angle = 360 - Math.abs(angle)
    }

    switch (true) {
      case angle <= 45 && angle >= 0:
        return 'right'
      case angle <= 360 && angle >= 315:
        return 'right'
      case angle >= 135 && angle <= 225:
        return 'left'
      case angle >= 35 && angle <= 135:
        return 'up'
      default:
        return 'down'
    }
  }

  bindEvents () {
    if (this.dragging) {
      on(this.element, 'mousedown', this.onTouchStart, this.captureOpts)
    }
    on(this.element, 'touchstart', this.onTouchStart, this.captureOpts)
  }

  unbindEvents () {
    if (this.dragging) {
      off(this.element, 'mousedown', this.onTouchStart, this.captureOpts)
    }
    off(this.element, 'touchstart', this.onTouchStart, this.captureOpts)
  }

  onTouchStart (e) {
    const eventType = e.type

    if (eventType === 'mousedown') {
      e.preventDefault()
    }

    const touches =
      eventType === 'mousedown'
        ? {
          pageX: e.clientX,
          pageY: e.clientY
        }
        : e.changedTouches[0]

    this.position = { x: touches.pageX, y: touches.pageY }

    emitter.emit(EVENTS.SWIPE_START, e)

    if (this.dragging) {
      on(document, 'mousemove', this.onTouchMove, this.captureOpts)
      on(document, 'mouseup', this.onTouchEnd, this.captureOpts)
    }

    on(document, 'touchmove', this.onTouchMove, this.captureOpts)
    on(document, 'touchend', this.onTouchEnd, this.captureOpts)
  }

  onTouchMove (e) {
    this.setPositions(e)
  }

  onTouchEnd (e) {
    if (this.dragging) {
      off(document, 'mousemove', this.onTouchMove, this.captureOpts)
      off(document, 'mouseup', this.onTouchEnd, this.captureOpts)
    }

    off(document, 'touchmove', this.onTouchMove, this.captureOpts)
    off(document, 'touchend', this.onTouchEnd, this.captureOpts)

    e.position = this.position
    e.touches = this.touches
    e.direction = this.getDirection(this.touches.x, this.touches.y)

    emitter.emit(EVENTS.SWIPE_END, e)

    this.position = { x: 0, y: 0 }
    this.touches = { x: 0, y: 0 }
  }

  static get EVENTS () {
    return EVENTS
  }
}
