import Component from '~/modules/Component'
import Resizer from '~/modules/Resizer'
import Swipe from '~/modules/Swipe'
import emitter from '~/modules/EventEmitter'
import { on, off } from '~/helpers/event'
import { setStyle } from '~/helpers/style'
import { toCamelCase } from '~/helpers/string'
import { normalizeIndex } from '~/helpers/math'
import { util } from '~/core'
import anime from 'animejs/lib/anime.es.js'

const NAME = 'slider'
const CLASSES = {
  SELECTED: 'is-selected'
}
const EVENTS = {
  BEFORE_SLIDE: `${NAME}:before`,
  AFTER_SLIDE: `${NAME}:after`
}

export default class Sllider extends Component {
  constructor (
    element,
    {
      activate = true,
      autoPlay = true,
      autoPlayDelay = 5000,
      duration = 2000,
      touchThreshold = 10,
      easeIn = 'easeInOutCubic',
      easeOut = 'easeOutCubic'
    } = {}
  ) {
    super(element, { name: NAME })

    this.options = {
      autoPlay,
      autoPlayDelay,
      duration,
      touchThreshold,
      easeIn,
      easeOut
    }

    this.direction = null
    this.itemWidth = 0
    this.containerWidth = 0
    this.currentIndex = 0
    this.itemsCount = this.dom.get('item').length
    this.isActivate = false
    this.isAnimating = false
    this.autoPlayTimer = null
    this.swipe = new Swipe(this.element, { dragging: true })

    this.bindAll('onClick', 'onResize', 'onSwipe')
    this.bindEvents()
    this.onResize()

    Resizer.add(this.onResize)

    if (activate) {
      this.activate()
    }
  }

  bindEvents () {
    if (this.dom.has('prev')) {
      on(this.dom.get('prev')[0], 'click', this.onClick)
    }

    if (this.dom.has('next')) {
      on(this.dom.get('next')[0], 'click', this.onClick)
    }

    emitter.on(Swipe.EVENTS.SWIPE_END, this.onSwipe)
  }

  unbindEvents () {
    if (this.dom.has('prev')) {
      off(this.dom.get('prev')[0], 'click', this.onClick)
    }

    if (this.dom.has('next')) {
      off(this.dom.get('next')[0], 'click', this.onClick)
    }

    emitter.off(Swipe.EVENTS.SWIPE_END, this.onSwipe)
  }

  onResize () {
    this.containerWidth = this.element.offsetWidth
    this.itemWidth = this.containerWidth

    setStyle(this.dom.get('item'), {
      width: `${this.itemWidth}px`
    })

    this.update()
  }

  onClick (e) {
    const target = e.target
    const name = target.dataset[toCamelCase(this.dataAttr)]

    switch (name) {
      case 'next':
        this.next()
        break
      case 'prev':
        this.prev()
        break
    }
  }

  onSwipe (e) {
    const { touchThreshold } = this.options
    if (Math.abs(e.touches.x) > touchThreshold) {
      if (e.direction === 'left') {
        this.next()
      } else if (e.direction === 'right') {
        this.prev()
      }
    }
  }

  setup () {
    this.dom.get('item').forEach((item, i) => {
      if (i === this.currentIndex) {
        item.classList.add(CLASSES.SELECTED)
        setStyle(item, { opacity: 1 })
      } else {
        setStyle(item, { opacity: 0 })
      }
    })
  }

  async navigate (index) {
    if (this.isAnimating) {
      return
    }
    this.isAnimating = true

    if (index > this.currentIndex) {
      this.direction = 'next'
    } else if (index < this.currentIndex) {
      this.direction = 'prev'
    }

    const { duration, easeIn, easeOut, autoPlay } = this.options
    const prev = this.currentIndex
    const current = normalizeIndex(index, this.itemsCount)
    const prevItem = this.dom.get('item')[prev]
    const curtItem = this.dom.get('item')[current]
    const tl = anime.timeline({
      duration
    })

    setStyle(prevItem, { zIndex: 2 })
    emitter.emit(EVENTS.BEFORE_SLIDE, prevItem, curtItem)

    await tl
      .add({
        targets: prevItem,
        opacity: 0,
        easing: easeIn
      })
      .add(
        {
          targets: curtItem,
          opacity: 1,
          easing: easeOut
        },
        `-=${duration}`
      ).finished

    setStyle(prevItem, { zIndex: '' })
    emitter.emit(EVENTS.AFTER_SLIDE, prevItem, curtItem)

    this.isAnimating = false
    this.currentIndex = current
    this.update()

    if (autoPlay) {
      this.autoPlay()
    }
  }

  prev () {
    return this.navigate(this.currentIndex - 1)
  }

  next () {
    return this.navigate(this.currentIndex + 1)
  }

  update () {
    this.updateClasses()
    this.updateItemTranslate()
  }

  updateClasses () {
    this.dom.get('item').forEach((item, i) => {
      if (i === this.currentIndex) {
        item.classList.add(CLASSES.SELECTED)
      } else {
        item.classList.remove(CLASSES.SELECTED)
      }
    })
  }

  updateItemTranslate () {
    this.dom.get('item').forEach((item, i) => {
      const x = -this.itemWidth * i
      setStyle(item, {
        [util.transformStr]: `translate3d(${x}px, 0, 0)`
      })
    })
  }

  clearTimer () {
    clearTimeout(this.autoPlayTimer)
  }

  async autoPlay () {
    const { autoPlayDelay } = this.options
    this.clearTimer()
    this.autoPlayTimer = setTimeout(() => this.next(), autoPlayDelay)
  }

  activate () {
    const { autoPlay } = this.options
    if (autoPlay) {
      this.autoPlay()
    }
    this.setup()
    this.update()
  }

  get current () {
    return this.dom.get('item')[this.currentIndex]
  }

  static get EVENTS () {
    return EVENTS
  }
}
