import Hero from './Hero'
import Slider from '~/ui/Slider'
import SequenceScroll from '~/ui/SequenceScroll'
import emitter from '~/modules/EventEmitter'
import bindAll from '~/helpers/bindAll'
import { trigger } from '~/helpers/event'

const CLASSES = {
  ZOOM_IMG: 'is-zoom-img'
}

export default class Top {
  constructor () {
    this.hero = new Hero('.js-hero')
    this.slider = new Slider('.js-slider', {
      activate: false
    })

    bindAll(this, 'onSlideBefore', 'onSlideAfter')
    this.bindEvents()
    this.init()
  }

  async init () {
    const current = this.slider.current
    await this.hero.load()
    this.sequenceScroll = new SequenceScroll(document.body)
    setTimeout(() => {
      current.classList.add(CLASSES.ZOOM_IMG)
    }, 1400)
    await this.hero.appear()
    this.slider.activate()
    await this.hero.animate()
    trigger(window, 'scroll')
  }

  bindEvents () {
    emitter.on(Slider.EVENTS.BEFORE_SLIDE, this.onSlideBefore)
    emitter.on(Slider.EVENTS.AFTER_SLIDE, this.onSlideAfter)
  }

  unbindEvents () {
    emitter.on(Slider.EVENTS.BEFORE_SLIDE, this.onSlideBefore)
    emitter.on(Slider.EVENTS.AFTER_SLIDE, this.onSlideAfter)
  }

  onSlideBefore (prev, current) {
    current.classList.add(CLASSES.ZOOM_IMG)
  }

  onSlideAfter (prev, current) {
    prev.classList.remove(CLASSES.ZOOM_IMG)
  }
}
