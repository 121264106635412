import Component from '~/modules/Component'
import Resizer from '~/modules/Resizer'
import emitter from '~/modules/EventEmitter'
import { detect } from '~/core'
import { on, off, trigger } from '~/helpers/event'
import { setStyle, getStyle } from '~/helpers/style'

const NAME = 'accordion'
const CLASSES = {
  SHOWN: 'is-accordion-opened'
}
const EVENTS = {
  TRANSITION_END: `${NAME}:transitionend`
}

export default class Accordion extends Component {
  constructor (element, { classes } = {}) {
    super(element, {
      name: NAME
    })

    this.winWidth = window.innerWidth
    this.bindAll('onClick', 'onResize', 'onTransitionEnd')

    this.CLASSES = {
      ...CLASSES,
      ...classes
    }

    Resizer.add(this.onResize)

    this.setup()
    this.bindEvents()
  }

  setup () {
    const content = this.dom.get('contents')[0]

    if (getStyle(content, 'display') === 'none') {
      setStyle(content, {
        display: 'block'
      })
    }
  }

  bindEvents () {
    on(this.dom.get('button')[0], 'click', this.onClick)
    on(this.dom.get('contents')[0], 'transitionend', this.onTransitionEnd)
  }

  unbindEvents () {
    off(this.dom.get('button')[0], 'click', this.onClick)
    off(this.dom.get('contents')[0], 'transitionend', this.onTransitionEnd)
  }

  onClick () {
    const content = this.dom.get('contents')[0]

    if (this.element.classList.contains(this.CLASSES.SHOWN)) {
      this.element.classList.remove(this.CLASSES.SHOWN)
      setStyle(content, {
        height: '0px'
      })
    } else {
      this.element.classList.add(this.CLASSES.SHOWN)
      setStyle(content, {
        height: `${this.dom.get('inner')[0].clientHeight}px`
      })
    }
  }

  onResize (innerWidth) {
    if (innerWidth === this.winWidth && detect.isIOS) {
      return
    }

    this.winWidth = innerWidth

    if (this.element.classList.contains(this.CLASSES.SHOWN)) {
      setStyle(this.dom.get('contents'), {
        height: `${this.dom.get('inner')[0].clientHeight}px`
      })
    }
  }

  reset () {
    this.element.classList.remove(this.CLASSES.SHOWN)
    setStyle(this.dom.get('contents'), {
      height: 'auto'
    })
  }

  activate () {
    this.setup()
    setStyle(this.dom.get('contents'), {
      height: ''
    })
  }

  onTransitionEnd () {
    trigger(window, 'resize')
    emitter.emit(EVENTS.TRANSITION_END)
  }

  static get EVENTS () {
    return EVENTS
  }
}
