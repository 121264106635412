import anime from 'animejs/lib/anime.es.js'
import { getOffset } from '~/helpers/dom'
import { dom } from '~/core'

const scrollTo = (
  element,
  { duration = 600, easing = 'linear', offset = 0, delay = 0 } = {}
) => {
  return anime({
    targets: [dom.body, dom.html],
    scrollTop: getOffset(element).top - offset,
    duration: duration,
    delay: delay,
    easing: easing
  }).finished
}

export default scrollTo
