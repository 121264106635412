import preloadImages from '~/helpers/preloadImages'
import getBackgroundImage from '~/helpers/getBackgroundImage'
import { isElement } from '~/helpers/type'

/**
 * Singleton
 */
class ImagesLoaded {
  getImages (nodes) {
    return Array.from(nodes)
      .map((el) => {
        if (el.tagName.toLowerCase() == 'img') {
          return el.src
        }
      })
      .filter(Boolean)
  }

  load (element = document.body) {
    if (!isElement(element)) return

    const nodes = element.querySelectorAll('*')

    return preloadImages.load([
      ...this.getImages(nodes),
      ...getBackgroundImage(nodes)
    ])
  }

  get loaded () {
    return preloadImages.loaded
  }

  get total () {
    return preloadImages.length
  }
}

export default new ImagesLoaded()
