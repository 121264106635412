/**
 * @see https://github.com/Polymer/pwa-helpers/blob/5cb02bacb6c6f72ceafeb1345de669e9986fcf70/src/media-query.ts
 * @example
 *  matchMedia('(min-width: 600px)', (matches) => {
 *    console.log(matches ? 'wide screen' : 'narrow sreen')
 *  })
 *
 * @param {string} queryStr
 * @param {Function} fn
 * @return {Function} destroy
 */
const matchMedia = (queryStr, fn) => {
  const mq = window.matchMedia(queryStr)
  const listener = (e) => fn(e.matches)
  mq.addListener(listener)
  fn(mq.matches)
  return {
    destroy: () => {
      mq.removeListener(listener)
    }
  }
}

export default matchMedia
