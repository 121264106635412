import { imageLoader } from './loader'

const preloadImages = {
  _loaded: 0,
  _length: 0,
  load: (paths = []) => {
    let loadedCount = 0
    preloadImages._length = paths.length
    return Promise.all(
      paths.map(async (path) => {
        await imageLoader(path)
        loadedCount++
        preloadImages._loaded = loadedCount
      })
    )
  },
  get length () {
    return preloadImages._length
  },
  get loaded () {
    return preloadImages._loaded
  }
}

export default preloadImages
