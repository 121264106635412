/**
 * @param  {string} str
 * @return {Array}
 */
const splitStrNumber = (str) => {
  return str.split(/(\d+)/).filter(Boolean)
}

/**
 * @example
 *  capitalize('fooBar'); // 'FooBar'
 *  capitalize('fooBar', true); // 'Foobar'
 */
const capitalize = ([first, ...rest], lowerRest = false) => {
  return (
    first.toUpperCase() +
    (lowerRest ? rest.join('').toLowerCase() : rest.join(''))
  )
}

/**
 * @example
 *  decapitalize('FooBar'); // 'fooBar'
 *  decapitalize('FooBar', true); // 'fOOBAR'
 */
const decapitalize = ([first, ...rest], upperRest = false) => {
  return (
    first.toLowerCase() +
    (upperRest ? rest.join('').toUpperCase() : rest.join(''))
  )
}

/**
 * @param  {string} str
 * @return {string}
 */
const toCamelCase = (str) => {
  const s =
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
      .join('')
  return s.slice(0, 1).toLowerCase() + s.slice(1)
}

/**
 * @param  {string} str
 * @return {string}
 */
const toKebabCase = (str) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-')
  )
}

/**
 * @param  {string} str
 * @return {string}
 */
const toSnakeCase = (str) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('_')
  )
}

/**
 * @example
 *  stripHTMLTags('<p><em>lorem</em> <strong>ipsum</strong></p>'); // 'lorem ipsum'
 * @param  {string} str
 * @return {string}
 */
const stripHTMLTags = (str) => {
  return str.replace(/<[^>]*>/g, '')
}

/**
 * @example
 *  escapeHTML('<a href="#">Me & you</a>'); // '&lt;a href=&quot;#&quot;&gt;Me &amp; you&lt;/a&gt;'
 * @param  {string} str
 * @return {string}
 */
const escapeHTML = (str) => {
  return str.replace(/[&<>'"]/g, (tag) => {
    return (
      {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;'
      }[tag] || tag
    )
  })
}

/**
 * @example
 *  unescapeHTML('&lt;a href=&quot;#&quot;&gt;Me &amp; you&lt;/a&gt;'); // '<a href="#">Me & you</a>'
 * @param  {string} str
 * @return {string}
 */
const unescapeHTML = (str) => {
  return str.replace(/&amp;|&lt;|&gt;|&#39;|&quot;/g, (tag) => {
    return (
      {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&#39;': "'",
        '&quot;': '"'
      }[tag] || tag
    )
  })
}

/**
 * @param  {string} str
 * @param  {number} idx
 * @param  {string} replace
 * @return {string}
 */
const replaceAt = (str, idx, replace) => {
  return str.substring(0, idx) + replace + str.substring(idx + 1)
}

/**
 * n番目にマッチした文字列を置き換える
 *
 * @see https://ja.stackoverflow.com/questions/34132/js%E3%81%A7n%E5%9B%9E%E7%9B%AE%E3%81%AB%E5%87%BA%E7%8F%BE%E3%81%99%E3%82%8B%E6%96%87%E5%AD%97%E3%81%A0%E3%81%91%E7%BD%AE%E6%8F%9B%E3%81%97%E3%81%9F%E3%81%84?answertab=active#tab-top
 * @param  {string} str
 * @param  {number} idx
 * @param  {string} expression
 * @param  {string} replace
 * @return {string}
 */
const replaceNth = (str, idx, expression, replace) => {
  const regex = new RegExp(expression, 'g')

  return str.replace(regex, (match) => {
    if (--idx === 0) {
      return replace
    } else {
      return match
    }
  })
}

const CSVToArray = (data, delimiter = ',', omitFirstRow = false) => {
  return data
    .slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
    .split('\n')
    .map((v) => v.split(delimiter))
}

const sprintf = (format, ...args) => {
  let i = 0
  return format.replace(/%s/g, () => args[i++])
}

export {
  splitStrNumber,
  capitalize,
  decapitalize,
  toCamelCase,
  toKebabCase,
  toSnakeCase,
  stripHTMLTags,
  escapeHTML,
  unescapeHTML,
  replaceAt,
  replaceNth,
  CSVToArray,
  sprintf
}
